import { initGTM } from "../tracking";
import { envVarToBool } from "../services/tools";
const { REACT_APP_LIBRARY_URL } = process.env;

export default (
  state = {
    component: {
      props: {},
    },
    isMobile: window.matchMedia("(max-width: 600px)").matches,
    language: "/fr",
    modules: [],
    linesModes: [],
    lines: [],
    stops: [],
    areas: [],
    languageFile: {},
    modes: {},
    walkingSpeeds: [],
    calculateItineraryNow: true,
    touchscreenSelected: null,
    isLegendOpen: false,
    heavyIds: [],
    datePicker: {
      minDate: undefined, // KEEP undefined instead of null for react-date-and-time-picker
      maxDate: undefined, // KEEP undefined instead of null for react-date-and-time-picker
      defaultDateTime: undefined, // KEEP undefined instead of null for react-date-and-time-picker
    },
    config: {
      scrollWheelZoom: true,
      dragging: true,
    },
    calendar: {
      date: new Date(),
      show: false,
    },
    isExpandedMap: false,
    hasAuthorizedCookies: false,
    print: {
      pages: [],
      datas: {},
      isPrinting: false,
    },
    placesRef: [],
    dontShow: [],
  },
  action
) => {
  switch (action.type) {
    case "INIT_APP":
      // to get rapidly line informations for stops and areas
      const routes = [];

      for (let l = 0; l < action.data.lines?.length; l++) {
        const line = action.data.lines[l];

        for (let r = 0; r < line.routes.length; r++) {
          const route = line.routes[r];

          routes.push({
            route_id: route.route_id,
            direction: route.direction,
            direction_id: route.direction_id,
            id: line.id,
            code: line.code,
            name: line.name,
            mode: line.mode,
            cat: line.cat,
            network: line.network,
            position: line.position,
          });
        }
      }

      // to get rapidly stop id for areas
      const stops = [];

      for (let i = 0; i < action.data.stops?.length; i++) {
        const stop = action.data.stops[i];
        const lines = [];

        for (let j = 0; j < stop.lines.length; j++) {
          const line = routes.find((r) => r.route_id === stop.lines[j].route_id);

          stops.push({ route_id: stop.lines[j].route_id, id: stop.id });

          if (line) {
            lines.push({
              ...line,
              stop_id: stop.id,
            });
          }
        }

        stop.lines = lines;
      }

      for (let i = 0; i < action.data.areas?.length; i++) {
        const area = action.data.areas[i];
        const lines = [];

        for (let j = 0; j < area.lines.length; j++) {
          const line = routes.find((r) => r.route_id === area.lines[j].route_id);
          const stop = stops.find((s) => s.route_id === area.lines[j].route_id);

          if (line && stop) {
            lines.push({
              id: line.id,
              code: line.code,
              name: line.name,
              mode: line.mode,
              cat: line.cat,
              network: line.network,
              position: line.position,
              direction_id: line.direction_id,
              stop_id: stop.id,
            });
          }
        }

        area.lines = lines;
      }

      return {
        ...state,
        ...action.data,
      };

    case "UPDATE_MEDIA_QUERIES":
      return {
        ...state,
        isMobile: action.isMobile,
      };

    case "SET_MAP":
      return {
        ...state,
        map: action.map,
      };

    case "SET_COMPONENT":
      return {
        ...state,
        component: action.component,
      };

    case "SCROLL_END":
      return {
        ...state,
        top: action.top,
      };

    case "SET_USER_TOKEN":
      return {
        ...state,
        token: action.token,
      };

    case "SET_USER_ID":
      return {
        ...state,
        clientId: action.client_id,
      };

    case "SET_FAVORITES_DATA":
      return {
        ...state,
        favoritesData: action.data,
      };

    case "SET_APP_LANGUAGE":
      return {
        ...state,
        language: action.language,
      };

    case "SET_APP_LOCKER":
      return {
        ...state,
        lock: action.lock === "true",
      };

    case "SET_APP_PANEL_SIZE":
      return {
        ...state,
        size: action.size === "false" ? false : ["small", "large"].includes(action.size) ? action.size : "large",
      };

    case "SET_APP_TRACKING":
      // set app tracking in message too to call this one

      if (action.tracking) {
        window.document.cookie = "hasAuthorizedCookies=true;path=/";
        initGTM();
      } else {
        if (!window.document.cookie.includes("hasAuthorizedCookies=true")) {
          window.document.cookie = "hasAuthorizedCookies=false;path=/";
        } else {
          window.document.cookie = "hasAuthorizedCookies=false;path=/";

          if (envVarToBool(REACT_APP_LIBRARY_URL) === false) {
            window.location.reload(); // reload because we cant disable GTM
          }
        }
      }

      return {
        ...state,
        hasAuthorizedCookies: action.tracking,
      };

    case "INIT_MODES":
      return {
        ...state,
        modes: action.modes,
      };

    case "HANDLE_MODES_CHANGED":
      // si on désélectionne pmr (action.mode.id === "pmr" && state.modes[action.mode.id]), on laisse sélectionné
      const modes = {
        ...state.modes,
        [action.mode.id]:
          action.mode.id === "pmr" && state.modes.length > 1 && state.modes[action.mode.id]
            ? true
            : !state.modes[action.mode.id],
      };

      let walkingSpeeds = state.walkingSpeeds;

      if (action.mode.id === "pmr") {
        if (modes.bss) {
          modes.bss = false;
        }

        walkingSpeeds = walkingSpeeds.map((walkingSpeed) => {
          return { ...walkingSpeed, value: false };
        });
      }

      return {
        ...state,
        walkingSpeeds: walkingSpeeds,
        modes: modes,
      };

    case "INIT_WALKING_SPEEDS":
      return {
        ...state,
        walkingSpeeds: action.walkingSpeeds,
      };

    case "HANDLE_WALKING_SPEEDS_CHANGED":
      const modesToSet = { ...state.modes };

      if (modesToSet.pmr !== undefined) {
        modesToSet.pmr = false;
      }

      return {
        ...state,
        walkingSpeeds: state.walkingSpeeds.map((walkingSpeed) => {
          if (walkingSpeed.type === action.walkingSpeedType) {
            return { ...walkingSpeed, value: true };
          } else {
            return { ...walkingSpeed, value: false };
          }
        }),
        modes: modesToSet,
      };

    case "INIT_DATEPICKER_MINMAX_DATE":
      return {
        ...state,
        datePicker: {
          minDate: action.minDate !== undefined ? new Date(action.minDate) : undefined,
          maxDate: action.maxDate !== undefined ? new Date(action.maxDate) : undefined,
          defaultDateTime: action.defaultDateTime !== undefined ? new Date(action.defaultDateTime) : undefined,
        },
      };

    case "INIT_DATE":
      return {
        ...state,
        selectedDate: action.date,
        selectedTime: action.date,
      };

    case "HANDLE_SELECTED_DATE_CHANGED":
      return {
        ...state,
        selectedDate: action.date,
        calculateItineraryNow: action.now,
      };

    case "HANDLE_SELECTED_TIME_CHANGED":
      return {
        ...state,
        selectedTime: action.date,
        calculateItineraryNow: false,
      };

    case "HANDLE_REPRESENTS_CHANGED":
      const modesRepresentChanged = {
        ...state.modes,
        represents: action.represent,
      };

      return {
        ...state,
        modes: modesRepresentChanged,
      };

    case "SET_APP_HASH":
      return {
        ...state,
        hash: action.hash,
      };

    case "SET_APP_HEADER":
      return {
        ...state,
        header: action.header,
      };

    case "SET_CONFIG":
      return {
        ...state,
        config: {
          ...state.config,
          ...action.config,
        },
      };

    case "SET_PROJECTS_MENU_ID":
      return {
        ...state,
        menuID: action.menuID,
      };

    case "SET_CALENDAR_DATE":
      const today = new Date();
      const nDate = new Date(action.date.getTime());

      const calcItiNow =
        nDate.getFullYear() + "" + nDate.getMonth() + "" + nDate.getDate() ===
        today.getFullYear() + "" + today.getMonth() + "" + today.getDate()
          ? true
          : false;

      // si on sélectionne la date du jour, on remet les heures et les minutes de maintenant
      if (calcItiNow) {
        nDate.setHours(today.getHours());
        nDate.setMinutes(today.getMinutes());
        nDate.setSeconds(today.getSeconds());
      } else {
        // sinon on remet les heures et les minutes précédement sélectionnées
        nDate.setHours(state.calendar.date.getHours());
        nDate.setMinutes(state.calendar.date.getMinutes());
        nDate.setSeconds(state.calendar.date.getSeconds());
      }

      return {
        ...state,
        calendar: {
          ...state.calendar,
          date: nDate,
        },
        calculateItineraryNow: calcItiNow,
      };

    case "SET_CALENDAR_DATETIME":
      let d = new Date();

      d.setTime(state.calendar.date.getTime());
      d.setHours(action.hour);
      d.setMinutes(action.minute);

      return {
        ...state,
        calendar: {
          ...state.calendar,
          date: d,
        },
        calculateItineraryNow: false,
      };

    case "SET_CALENDAR_SHOW":
      return {
        ...state,
        calendar: {
          ...state.calendar,
          show: action.show,
        },
      };
    case "SET_EXPANDED_MAP":
      if (envVarToBool(process.env.REACT_APP_EXPANDED_MAP) && state.isMobile) {
        if (document.scrollingElement) {
          document.scrollingElement.scrollTo(0, 0);
        }

        if (state.map?.mapReference?.current?.leafletElement) {
          setTimeout(() => {
            // force leaflet to update map size
            state.map.mapReference.current.leafletElement.invalidateSize();
          });
        }
      }

      return {
        ...state,
        isExpandedMap: action.isExpandedMap,
      };

    case "ADD_STOPS":
      return {
        ...state,
        stops: [...state.stops, ...action.stops.map((s, is) => ({ ...s, index: state.stops.length + is }))],
      };

    case "ADD_LINE":
      return {
        ...state,
        lines: [...state.lines, { ...action.line, index: state.lines.length }],
      };

    case "SET_PRINT":
      return {
        ...state,
        print: { ...action.print, isPrinting: true },
      };

    case "SET_PlACES":
      return {
        ...state,
        places: action.places,
      };

    case "SET_RADIUS":
      return {
        ...state,
        currentRadius: +action.radius,
      };

    case "SET_STREETVIEW_POSITION":
      return {
        ...state,
        streetviewPosition: action.coords,
      };

    case "SET_DONT_SHOW":
      return {
        ...state,
        dontShow: action.list.split(","),
      };

    default:
      return state;
  }
};
