export const actionInputValueChange = (value) => ({
  type: "ON_INPUT_VALUE_CHANGE",
  value,
});

export const actionInputStartValueChange = (value) => ({
  type: "ON_START_INPUT_VALUE_CHANGE",
  value,
});

export const actionInputEndValueChange = (value) => ({
  type: "ON_END_INPUT_VALUE_CHANGE",
  value,
});

export const actionInputItemsChange = (items) => ({
  type: "ON_INPUT_ITEMS_CHANGE",
  items,
});

export const actionInputsSwap = (from, to) => ({
  type: "ON_INPUTS_SWAP",
  from,
  to,
});

export const actionInputStartItemsChange = (items) => ({
  type: "ON_START_INPUT_ITEMS_CHANGE",
  items,
});

export const actionInputEndItemsChange = (items) => ({
  type: "ON_END_INPUT_ITEMS_CHANGE",
  items,
});

export const actionSetThematicPlaces = (thematicPlaces) => ({
  type: "SET_THEMATIC_PLACES",
  thematicPlaces,
});

export const actionSetThematicLibMarkers = (libraryThematicMarkers) => ({
  type: "SET_THEMATIC_LIB_MARKERS",
  libraryThematicMarkers,
});

export const actionSetThematicLibDisplay = (libraryThematicDisplay) => ({
  type: "SET_THEMATIC_LIB_DISPLAY",
  libraryThematicDisplay,
});

export const actionSetThematicLibPredict = (libraryThematicPredict) => ({
  type: "SET_THEMATIC_LIB_PREDICT",
  libraryThematicPredict,
});

export const actionSetPlaceClicked = (place) => ({
  type: "SET_PLACE_CLICKED",
  place,
});

export const actionSetOpenedCollapse = (collapse) => ({
  type: "SET_OPENED_COLLAPSE",
  collapse,
});

export const actionSetDisruptions = (disruptions) => ({
  type: "SET_DISRUPTIONS",
  disruptions,
});

export const actionSetDisruptionInLine = (disruptionsInLine) => ({
  type: "SET_DISRUPTION_IN_LINE",
  disruptionsInLine,
});

export const actionSetGeolocationError = (error) => ({
  type: "SET_GEOLOCATION_ERROR",
  error,
});

export const actionSetLineInformation = (info) => ({
  type: "SET_LINE_INFORMATION",
  info,
});
// TODO rename logic ...
export const actionGoToValid = (valid) => ({
  type: "SET_GO_TO_ROUTE_CALC",
  valid,
});

export const actionResetStationIndex = () => ({ type: "RESET_STATION_INDEX" });

export const actionIncrementStationIndex = (direction) => ({
  type: "INCREMENT_STATION_INDEX",
  direction,
});

export const actionDecrementStationIndex = (direction) => ({
  type: "DECREMENT_STATION_INDEX",
  direction,
});

export const actionSetRouteCalcDatesPanel = (datesPanel) => ({
  type: "SET_ROUTE_CALC_DATES_PANEL",
  datesPanel,
});

export const actionSetRouteCalcModesPanel = (modesPanel) => ({
  type: "SET_ROUTE_CALC_MODES_PANEL",
  modesPanel,
});

export const actionSetAroundRadiusPanel = (radiusPanel) => ({
  type: "SET_AROUND_RADIUS_PANEL",
  radiusPanel,
});

export const actionSetRouteCalcWalkingSpeedsPanel = (walkingSpeedsPanel) => ({
  type: "SET_ROUTE_CALC_WALKINGSPEEDS_PANEL",
  walkingSpeedsPanel,
});

export const actionSetMobilityObject = (mobilityObject) => ({
  type: "SET_MOBILITY_OBJECT",
  mobilityObject,
});

export const actionSetLinesRegionsPanel = (code, open) => ({
  type: "SET_LINESREGIONS_PANEL",
  code,
  open,
});

export const actionSetDdlsPanel = (code, open) => ({
  type: "SET_DDLS_PANEL",
  code,
  open,
});

export const actionCleanLinesRegions = () => ({
  type: "CLEAN_LINESREGIONS",
});

export const actionSetFavoriteLine = (line) => ({
  type: "SET_FAVORITE_LINE",
  line,
});

export const actionSetFavoriteStop = (stop) => ({
  type: "SET_FAVORITE_STOP",
  stop,
});

export const actionSetDisplayError = (id, typeError, message) => ({
  type: "SET_DISPLAY_ERROR",
  id,
  typeError,
  message,
});

export const actionSetHideDisplayError = (id) => ({
  type: "SET_HIDE_DISPLAY_ERROR",
  id,
});

export const actionSetTimetableData = (data) => ({
  type: "SET_DISPLAY_TIMETABLE_DATA",
  data,
});
