import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { appStore } from "../store";
import { toggleModalGeneric } from "../actions/modalGeneric";
import { handleKeyPress, translate } from "../services/tools";
import axios from "../middlewares/axios";

class ModalGeneric extends Component {
  scrollingElement = null;
  modal = null;

  state = {
    content: null,
  };

  async updateModalContent() {
    const { language, modalFile, hasAuthorizedCookies } = this.props;

    // Load text to display
    try {
      const response = await axios.get(`/api/file?folder=modals&ext=html&name=${modalFile}_${language}`);

      if (modalFile === "legal-notice") {
        this.setState({
          content: response.data.replace(
            "VALUE_COOKIE",
            translate(`legal-notice-${hasAuthorizedCookies ? "cookies-refuse" : "cookies-accept"}`)
          ),
        });
      } else {
        this.setState({ content: response.data });
      }
    } catch (e) {
      console.warn(`Can't load : ${modalFile}_${language}`, e);
      return;
    }
  }

  componentDidMount() {
    this.modal = document.querySelector("[data-lc-modal]");

    // Add active class to modal
    this.modal.classList.add("lc-active");

    this.modal.addEventListener("click", this.hide);

    this.updateModalContent();
  }

  componentDidUpdate(prevProps) {
    const { hasAuthorizedCookies } = this.props;

    if (prevProps.hasAuthorizedCookies !== hasAuthorizedCookies) {
      this.updateModalContent();
    }
  }

  componentWillUnmount() {
    this.modal.removeEventListener("click", this.hide);
  }

  hide = (e) => {
    const { modalFile } = this.props;

    if (e && e.target.classList[0] !== "lc-modal" && e.target.classList[0] !== "lc-close") {
      return;
    }

    // Add hide class to modal
    this.modal.classList.remove("lc-active");

    setTimeout(() => {
      appStore.dispatch(toggleModalGeneric(modalFile));
    }, 250);
  };

  render() {
    const { modalFile } = this.props;

    if (!process.env.REACT_APP_LIBRARY_URL) {
      // Temporary fix. TODO : change all index.html with a portal ID of lcmap-modal
      document.getElementById("portal") && document.getElementById("portal").setAttribute("id", "lcmap-modal");
    }

    return ReactDOM.createPortal(
      <div className={`lc-modal ${modalFile}`} data-lc-modal>
        <div className="lc-head">
          <div
            className={`lc-close ${modalFile}`}
            onKeyPress={(e) => handleKeyPress(e, this.hide)}
            role="button"
            tabIndex="0"
          />
          <div
            className="lc-modal-content lc-scroll"
            data-lc-scroll="scroll"
            dangerouslySetInnerHTML={{
              __html: this.state.content,
            }}
          ></div>
        </div>
      </div>,
      document.getElementById("lcmap-modal")
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.modalGeneric,
    language: state.app.language,
    hasAuthorizedCookies: state.app.hasAuthorizedCookies,
  };
};

export default connect(mapStateToProps)(ModalGeneric);
