/**
 * Use this action to dispatch multiple actions in a row
 * @param actions
 * @returns {{type: string, actions: *[]}}
 */
export const batch = (...actions) => ({
  type: "BATCH",
  actions,
});

export const actionInitApp = (data) => ({
  type: "INIT_APP",
  data,
});

export const actionUpdateMediaQueries = (isMobile) => ({
  type: "UPDATE_MEDIA_QUERIES",
  isMobile,
});

export const actionSetMap = (map) => ({
  type: "SET_MAP",
  map,
});

export const actionSetComponent = (component) => ({
  type: "SET_COMPONENT",
  component,
});

export const actionScrollEnd = (top) => ({
  type: "SCROLL_END",
  top,
});

export const actionSetUserToken = (token) => ({
  type: "SET_USER_TOKEN",
  token,
});

export const actionSetUserID = (client_id) => ({
  type: "SET_USER_ID",
  client_id,
});

export const actionSetFavoritesData = (data) => ({
  type: "SET_FAVORITES_DATA",
  data,
});

export const actionSetAppLanguage = (language) => ({
  type: "SET_APP_LANGUAGE",
  language,
});

export const actionSetAppLocker = (lock) => ({
  type: "SET_APP_LOCKER",
  lock,
});

export const actionSetAppPanelSize = (size) => ({
  type: "SET_APP_PANEL_SIZE",
  size,
});

export const actionSetAppTracking = (tracking) => ({
  type: "SET_APP_TRACKING",
  tracking,
});

export const actionInitModes = (modes) => ({
  type: "INIT_MODES",
  modes,
});

export const actionHandleModesChanged = (mode) => ({
  type: "HANDLE_MODES_CHANGED",
  mode,
});

export const actionInitWalkingSpeeds = (walkingSpeeds) => ({
  type: "INIT_WALKING_SPEEDS",
  walkingSpeeds,
});

export const actionHandleWalkingSpeedsChanged = (walkingSpeedType) => ({
  type: "HANDLE_WALKING_SPEEDS_CHANGED",
  walkingSpeedType,
});

export const actionInitDatePickerMinMaxDate = (minDate, maxDate, defaultDateTime) => ({
  type: "INIT_DATEPICKER_MINMAX_DATE",
  minDate,
  maxDate,
  defaultDateTime,
});

export const actionInitDate = (date) => ({
  type: "INIT_DATE",
  date,
});

export const actionHandleSelectedDateChanged = (date, now) => ({
  type: "HANDLE_SELECTED_DATE_CHANGED",
  date,
  now,
});

export const actionHandleSelectedTimeChanged = (date) => ({
  type: "HANDLE_SELECTED_TIME_CHANGED",
  date,
});

export const actionHandleRepresentsChanged = (represent) => ({
  type: "HANDLE_REPRESENTS_CHANGED",
  represent,
});

export const actionSetHash = (hash) => ({
  type: "SET_APP_HASH",
  hash,
});

export const actionSetHeader = (header) => ({
  type: "SET_APP_HEADER",
  header,
});

export const actionSetConfig = (config) => ({
  type: "SET_CONFIG",
  config,
});

export const actionSetProjectsMenuID = (menuID) => ({
  type: "SET_PROJECTS_MENU_ID",
  menuID,
});

export const actionSetCalendarDate = (date) => ({
  type: "SET_CALENDAR_DATE",
  date,
});

export const actionSetCalendarDateTime = (hour, minute) => ({
  type: "SET_CALENDAR_DATETIME",
  hour,
  minute,
});

export const actionSetCalendarShow = (show) => ({
  type: "SET_CALENDAR_SHOW",
  show,
});

export const actionSetExpandedMap = (isExpandedMap) => ({
  type: "SET_EXPANDED_MAP",
  isExpandedMap,
});

export const actionAddStops = (stops) => ({
  type: "ADD_STOPS",
  stops,
});

export const actionAddLine = (line) => ({
  type: "ADD_LINE",
  line,
});

export const actionSetPrint = (print) => ({
  type: "SET_PRINT",
  print,
});

export const actionSetPlaces = (places) => ({
  type: "SET_PlACES",
  places,
});

export const actionSetRadius = (radius) => ({
  type: "SET_RADIUS",
  radius,
});

export const actionSetStreetviewPosition = (coords) => ({
  type: "SET_STREETVIEW_POSITION",
  coords,
});

export const actionSetDontShow = (list) => ({
  type: "SET_DONT_SHOW",
  list,
});
