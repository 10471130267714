import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { translate, assetsPath } from "../services/tools";
import { formatDate, navitiaDateToDate } from "../utils/tools";
import { getLine, renderLine } from "../utils/leaflet/map";
import { Page, Header, Title, TitleText, TitleImg } from "./commun";

const PrintTimetable = (props) => {
  const language = useSelector((state) => state.app.language);
  const { timetableData, timetableHours } = useSelector((state) => state.board);
  const { datas, page } = props;
  const [component] = useState(datas.component);
  const [canPrint, setCanPrint] = useState(false);

  const line = getLine(component, {
    id: page.line.substring(0, page.line.lastIndexOf("_")),
  });

  line.direction_id = page.line.slice(-1);

  useEffect(() => {
    if (timetableData) {
      // Check in the DOM if an lc-timetable element is present
      const timetable = document.querySelector(".lc-timetable-stop");

      if (timetable) {
        setTimeout(() => {
          const destinations = document.querySelectorAll(".lc-otherDirections");
          const append = [];

          for (const dest of destinations) {
            if (append.indexOf(dest.innerHTML) === -1) {
              document.querySelector(".lc-scroll").append(dest);
              dest.style.marginLeft = "20px";
              append.push(dest.innerHTML);
            } else {
              dest.remove();
            }
          }
        });
      }

      setCanPrint(true);
    }

    // eslint-disable-next-line
  }, [timetableData, timetableHours]);

  return (
    <Page>
      <Header>
        <Title>
          <TitleImg src={assetsPath("/assets/images/menu/lines.svg")} />
          <TitleText>
            {translate("print-page-timetable-title", false, {
              key: "date",
              value: formatDate(navitiaDateToDate(`${page.date}T1000`), "full-with-year", language),
            })}
          </TitleText>
        </Title>
      </Header>
      {timetableData && renderLine(component, line, true, { timetableData, timetableHours })}
      {canPrint && <div id="can-print"></div>}
    </Page>
  );
};

export default PrintTimetable;
